import React from 'react';

import styles from './Burger.module.css';
import BurgerIngredient from './BurgerIngredient/BurgerIngredient';

const burger = (props) => {
	// const ingredients = [];
	// for (let ingredientName in props.ingredients) {
	// 	ingredients.push({
	// 		name: ingredientName,
	// 		amount: props.ingredients[ingredientName],
	// 	});
	// }

	// this is a stupid complicated function
	let ingredients = Object.keys(props.ingredients)
		.map((igKey) => {
			return [...Array(props.ingredients[igKey])].map((_, i) => {
				return <BurgerIngredient key={igKey + i} type={igKey} />;
			});
		})
		.reduce((arr, el) => {
			return arr.concat(el);
		}, []);

	if (ingredients.length === 0) {
		ingredients = <p>Please start adding ingredients!</p>;
	}

	return (
		<div className={styles.Burger}>
			<BurgerIngredient type="bread-top" />
			{ingredients}
			<BurgerIngredient type="bread-bottom" />
		</div>
	);
};

export default burger;
